import imageCompression from 'browser-image-compression'
export const imageCompressor = async (imageFile) => {

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }

    const compressedFile = await imageCompression(imageFile, options)
    return new File([compressedFile], compressedFile.name, {
        type: compressedFile.type
    })

}
