// ** Redux Imports
import { createSlice, createAsyncThunk, current, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from '../../shared/authentication/AxiosInterceptor'
import { CustomerData } from '../models/CustomerData'

// ** Axios Imports

export const getCustomer = createAsyncThunk('customers/getCustomer', async (id) => {
    const response = await axiosInstance.get(`customer/${id}`)
    return response.data
})

export const getCustomerList = createAsyncThunk('customers/getCustomerList', async () => {
    const response = await axiosInstance.get('customer')
    return response.data
})

export const getCustomerListByPage = createAsyncThunk('customers/getCustomerListByPage', async (data) => {
    const { currentPage, perPage, searchValue } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)

    const response = await axiosInstance.post('customer/page', body)
    return response.data
})

export const getCustomerListByDates = createAsyncThunk('customers/getCustomerListByDates', async (data) => {
    const { currentPage, perPage, searchValue, fromDates, toDates } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("fromDate", fromDates)
    body.append("toDate", toDates)

    const response = await axiosInstance.post('customer/page/dates', body)
    return response.data
})

export const addCustomer = createAsyncThunk('customers/addCustomer', async (data) => {
    const response = await axiosInstance.post('customer', data)
    return response.data
})

export const updateCustomer = createAsyncThunk('customers/updateCustomer', async (data) => {
    const response = await axiosInstance.put('customer', data)
    return response.data
})
export const updateCustomerStatus = createAsyncThunk('customers/updateCustomer', async (data) => {
    const response = await axiosInstance.post(`/customer/${data.id}/activate-deactivate?active=${data.active}`)
    return response.data
})

export const deleteCustomer = createAsyncThunk('customers/deleteCustomer', async (id) => {
    const response = await axiosInstance.delete(`customer/${id}`)
    return response.data
})

export const getCustomerOrdersByStatus = createAsyncThunk('customers/getCustomerOrdersByStatus', async (data) => {
    const { currentPage, perPage, searchValue, orderStatus, id } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("status", orderStatus)
    body.append("customerId", id)

    const response = await axiosInstance.post('order/customer/page/status', body)
    return response.data
})


const initialState = {
    customers: [CustomerData]
}

export const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder
            .addCase(getCustomerList.fulfilled, (state, action) => {
                Object.assign(state.customers, action.payload)

            })

            .addCase(getCustomerListByDates.fulfilled, (state, action) => {
                Object.assign(state.customers, action.payload.data)

            })

            .addCase(getCustomerListByPage.fulfilled, (state, action) => {
                Object.assign(state.customers, action.payload.data)

            })

    }
})

export default customersSlice.reducer
