// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../shared/authentication/AxiosInterceptor'
import { imageCompressor } from '../../shared/components/imageCompressor/imageCompressor'
import { ProductDataModel } from '../models/ProductData'

// ** Axios Imports

//master product
export const getProduct = createAsyncThunk('products/getProduct', async (id) => {
    const response = await axiosInstance.get(`product/${id}`)
    return response.data
})

export const addItemInBulk = createAsyncThunk('products/addItemInBulk', async (data) => {

    const response = await axiosInstance.post(`store-product/bulk-upload/${data.storeId}`, data.formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    const res = response.data
    const modifiedErrorMessage = res.replaceAll("\\s+", "")
    return modifiedErrorMessage
})

// POST/food-item/bulk-upload/{storeId}
export const addItemInBulkFood = createAsyncThunk('food/addItemInBulkFood', async (data) => {

    const response = await axiosInstance.post(`food-item/bulk-upload/${data.storeId}`, data.formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    const res = response.data
    const modifiedErrorMessage = res.replaceAll("\\s+", "")
    return modifiedErrorMessage
})

export const addItemInBulkAdmin = createAsyncThunk('products/addItemInBulkAdmin', async (data) => {

    const response = await axiosInstance.post(`product/bulk-upload`, data.formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    const res = response.data
    const modifiedErrorMessage = res.replaceAll("\\s+", "")
    return modifiedErrorMessage
})


export const getProductByBarcode = createAsyncThunk('products/getProductByBarcode', async (data) => {
    const response = await axiosInstance.get(`product/barcode/${data.barcode}/store/${data.id}`)
    return response.data
})


// export const getProductBarcodes = createAsyncThunk('products/getProductBarcodes', async () => {
//     const response = await axiosInstance.get('product/barcode')
//     return response.data
// })

export const getProductBarcodes = createAsyncThunk('product/getProductBarcodes', async (number) => {
    const response = await axiosInstance.get(`/product/barcode?searchTerm=${number}`)
    return response.data
})

// export const getProductList = createAsyncThunk('products/getProductList', async () => {
//     const response = await axiosInstance.get('product')
//     return response.data
// })

export const getProductCount = createAsyncThunk('products/getProductCount', async (id) => {
    const response = await axiosInstance.get(`product/count`)
    return response.data
})

export const getProductImage = createAsyncThunk('products/getProductImage', async (filename) => {
    const response = await axiosInstance.get(`getProductImage/${filename}`)
    return response.data
})

export const getProductByPage = createAsyncThunk('products/getProductByPage', async (data) => {
    const { currentPage, perPage, searchValue } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)

    const response = await axiosInstance.post('product/page', body)
    return response.data
})

export const getProductsByStatus = createAsyncThunk('products/getProductsByStatus', async (data) => {
    const { currentPage, perPage, searchValue, productStatus } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("status", productStatus)

    const response = await axiosInstance.post('product/page/status', body)
    return response.data
})

export const getProductsByCategory = createAsyncThunk('products/getProductsByCategory', async (data) => {
    const { currentPage, perPage, searchValue, id } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("categoryId", id)

    const response = await axiosInstance.post('product/page/category', body)
    return response.data
})

export const addProduct = createAsyncThunk('products/addProduct', async (data) => {
    const response = await axiosInstance.post('product', data)
    return response.data
})


export const addToCategory = createAsyncThunk('products/addToCategory', async (data) => {
    const response = await axiosInstance.post('product/add/category', data)
    return response.data
})
export const removeMultiProduct = createAsyncThunk('products/removeMutliProduct', async (data) => {
    const response = await axiosInstance.post('product/delete', data)
    return response.data
})

export const deleteProduct = createAsyncThunk('products/deleteProduct', async (id) => {
    const response = await axiosInstance.delete(`product/${id}`)
    return response.data
})

export const uploadBulkProduct = createAsyncThunk('products/uploadBulkProduct', async (data) => {
    const formData = new FormData()
    formData.append('file', data)
    const response = await axiosInstance.post('product/bulk-upload', formData)
    return response.data
})
export const uploadProductImage = createAsyncThunk('products/uploadProductImage', async (data) => {
    let newImage = data
    if (data.size > 1000000) newImage = await imageCompressor(data)

    const formData = new FormData()
    formData.append('file', newImage)
    const response = await axiosInstance.post('upload-file', formData)
    return response.data
})


export const applyProductAction = createAsyncThunk('products/applyProductAction', async (data) => {
    const { action, id } = data

    let body = new FormData()
    body.append("action", action)

    const response = await axiosInstance.post(`product/action/${id}`, body)
    return response.data
})
export const setMultiProductByStatus = createAsyncThunk('products/setMultiProductByStatus', async (data) => {
    const response = await axiosInstance.post(`product/action-products`, data)
    return response.data
})
export const updateProducts = createAsyncThunk('products/updateProducts', async (data) => {

    const response = await axiosInstance.post(`product/update`, data)
    return response.data
})

export const updateProductPrice = createAsyncThunk('products/updateProductPrice', async (data) => {
    const { productId, price } = data

    let body = new FormData()
    body.append("price", price)

    const response = await axiosInstance.put(`product/price/${productId}`, body)
    return response.data
})

export const updateProductStatus = createAsyncThunk('products/updateProductStatus', async (data) => {
    const { productId, productStatus } = data

    let body = new FormData()
    body.append("status", productStatus)

    const response = await axiosInstance.put(`product/status/${productId}`, body)
    return response.data
})

export const getProductCategories = createAsyncThunk('products/getProductCategories', async (filename) => {
    const response = await axiosInstance.get(`product/category`)
    return response.data
})

export const existBarcode = createAsyncThunk('products/existBarcode', async (barcode) => {
    const response = await axiosInstance.get(`product/barcode/exist/${barcode}`)
    return response.data
})

//food items
export const getFoodItem = createAsyncThunk('products/getFoodItem', async (id) => {
    const response = await axiosInstance.get(`food-item/${id}`)
    return response.data
})
export const getFoodItemsByPage = createAsyncThunk('products/getFoodItemsByPage', async (data) => {
    const { currentPage, perPage, searchValue, id } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("storeId", id)

    const response = await axiosInstance.post('food-item/page', body)
    return response.data
})

export const getFoodItemsByStatus = createAsyncThunk('products/getFoodItemsByStatus', async (data) => {
    const { currentPage, perPage, searchValue, foodItemStatus, id } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("status", foodItemStatus)
    body.append("storeId", id)

    const response = await axiosInstance.post('food-item/page/status', body)
    return response.data
})
export const getFoodItemsByCategory = createAsyncThunk('products/getFoodItemsByCategory', async (data) => {
    const { currentPage, perPage, searchValue, id, categoryId } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("storeId", id)
    body.append("categoryId", categoryId)

    const response = await axiosInstance.post('food-item/page/category', body)
    return response.data
})

export const getFoodCategoryNames = createAsyncThunk('products/getFoodCategoryNames', async (id) => {

    const response = await axiosInstance.get(`food-item/category/${id}`)
    return response.data
})
export const handleDeleteMultiInventory = createAsyncThunk('products/getFoodCategoryNames', async (data) => {

    const response = await axiosInstance.post(`/store-product/delete`, data)
    return response.data
})
export const handleDeleteMultiFood = createAsyncThunk('products/getFoodCategoryNames', async (data) => {

    const response = await axiosInstance.post(`/food-item/delete`, data)
    return response.data
})
export const addFoodItem = createAsyncThunk('products/addFoodItem', async (itemData) => {
    const { data, id } = itemData
    const response = await axiosInstance.post(`food-item/${id}`, data)
    return response.data
})

export const addNewFoodCategory = createAsyncThunk('products/addNewFoodCategory', async (itemData) => {
    const { id, name } = itemData

    let body = new FormData()
    body.append("storeId", id)
    body.append("categoryName", name)
    const response = await axiosInstance.post(`food-item/add/category`, body)
    return response.data
})

export const addToFoodCategory = createAsyncThunk('products/addToFoodCategory', async (data) => {
    const response = await axiosInstance.post('food-item/add-to/category', data)
    return response.data
})

export const saveFoodCategory = createAsyncThunk('products/saveFoodCategory', async (data) => {
    const { id, name } = data

    let body = new FormData()
    body.append("storeId", id)
    body.append("name", name)
    const response = await axiosInstance.post('food-item/save/category', body)
    return response.data
})

export const deleteFoodCategory = createAsyncThunk('products/deleteFoodCategory', async (name) => {
    const response = await axiosInstance.delete(`food-item/delete/category/${name}`)
    return response.data
})

export const updateFoodItems = createAsyncThunk('products/updateFoodItems', async (data) => {

    const response = await axiosInstance.post(`food-item/update`, data)
    return response.data
})

export const updateFoodItemPrice = createAsyncThunk('products/updateFoodItemPrice', async (data) => {
    const { productId, price } = data
    let body = new FormData()
    body.append("price", price)

    const response = await axiosInstance.put(`food-item/price/${productId}`, body)
    return response.data
})

export const updateFoodItemStatus = createAsyncThunk('products/updateFoodItemStatus', async (data) => {
    const { productId, productStatus } = data
    let body = new FormData()
    body.append("status", productStatus)

    const response = await axiosInstance.put(`food-item/status/${productId}`, body)
    return response.data
})
//store products
export const getPendingProductCount = createAsyncThunk('products/getPendingProductCount', async (id) => {
    const response = await axiosInstance.get(`store-product/pending/count`)
    return response.data
})
export const getStoreProduct = createAsyncThunk('products/getStoreProduct', async (id) => {
    const response = await axiosInstance.get(`store-product/${id}`)
    return response.data
})
export const getStoreProductCategories = createAsyncThunk('products/getStoreProductCategories', async (id) => {
    const response = await axiosInstance.get(`store-product/category/${id}`)
    return response.data
})
export const getPendingStoreProducts = createAsyncThunk('products/getPendingStoreProducts', async (data) => {
    const { currentPage, perPage, searchValue, productStatus } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("status", productStatus)

    const response = await axiosInstance.post('store-product/page/status', body)
    return response.data
})
export const getStoreProductsByCategory = createAsyncThunk('products/getStoreProductsByCategory', async (data) => {
    const { currentPage, perPage, searchValue, store, category } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("storeId", store)
    body.append("categoryId", category)

    const response = await axiosInstance.post('store-product/page/category', body)
    return response.data
})
export const getStoreProductsByStatus = createAsyncThunk('products/getStoreProductsByStatus', async (data) => {
    const { currentPage, perPage, searchValue, id, productStatus } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("storeId", id)
    body.append("status", productStatus)

    const response = await axiosInstance.post('store-product/page/store/status', body)
    return response.data
})

export const addStoreProduct = createAsyncThunk('products/addStoreProduct', async (data) => {
    const response = await axiosInstance.post(`store-product`, data)
    return response.data
})
export const getGTIN = createAsyncThunk('product/getGTIN', async (data) => {
    const response = await axiosInstance.get(`product/getGTIN?GTIN=${data}`)
    return response.data
})

export const addStoreProductFromList = createAsyncThunk('products/addStoreProduct', async (data) => {
    const { storeId, storeProduct } = data
    const response = await axiosInstance.post(`store-product/add?storeId=${storeId}`, storeProduct)
    return response.data
})

export const updateStoreProducts = createAsyncThunk('products/updateStoreProducts', async (data) => {

    const response = await axiosInstance.post(`store-product/update`, data)
    return response.data
})

export const updateStoreProductPrice = createAsyncThunk('products/updateProductPrice', async (data) => {
    const { productId, price } = data

    let body = new FormData()
    body.append("price", price)

    const response = await axiosInstance.put(`store-product/price/${productId}`, body)
    return response.data
})

export const updateStoreProductStatus = createAsyncThunk('products/updateProductStatus', async (data) => {
    const { productId, productStatus } = data

    let body = new FormData()
    body.append("status", productStatus)

    const response = await axiosInstance.put(`store-product/status/${productId}`, body)
    return response.data
})

export const addCategoryStore = createAsyncThunk('products/addToCategoryStore', async (data) => {
    const response = await axiosInstance.post('store-product/add/category', data)
    return response.data
})


export const uploadBulkStoreProduct = createAsyncThunk('products/uploadBulkStoreProduct', async (data) => {
    const { id, fileData } = data

    const formData = new FormData()

    formData.append('file', fileData)
    const response = await axiosInstance.post(`store-product/bulk-upload/${id}`, formData)
    return response.data
})

//inventory list
export const getInventoryByPage = createAsyncThunk('products/getInventoryByPage', async (data) => {
    const { id, currentPage, perPage, searchValue } = data

    let body = new FormData()
    body.append("id", id)
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)

    const response = await axiosInstance.post('product/page/inventory', body)
    return response.data
})


const initialState = {
    products: [ProductDataModel]
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder

            .addCase(getProductByPage.fulfilled, (state, action) => {
                Object.assign(state.products, action.payload.data)

            })

    }
})


export default productsSlice.reducer
