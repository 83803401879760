// ** Redux Imports
import { createSlice, createAsyncThunk, current, PayloadAction, isRejected } from '@reduxjs/toolkit'
import { collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore'
import axiosInstance from '../../shared/authentication/AxiosInterceptor'
import { imageCompressor } from '../../shared/components/imageCompressor/imageCompressor'
import { db } from '../../shared/firebase/firebase'
import { CustomerData } from '../models/CustomerData'

// ** Axios Imports


export const getEnrollStoreRequest = createAsyncThunk('storeRequest/getEnrollStoreRequest', async (data) => {
    const { currentPage, perPage, searchValue } = data

    let body = new FormData()
    body.append("pageno", currentPage)
    body.append("perpage", perPage)
    body.append("search", searchValue)

    const response = await axiosInstance.post('app/store-request/page', body)
    return response.data
})

export const fetchGroupByUserID = createAsyncThunk('storeRequest/getGroup', async (uid) => {
    let response
    response = await new Promise((resolve, reject) => {

        try {
            let q = query(collection(db, "group"), where("members", "array-contains", uid))
            onSnapshot(q, (querySnapshot) => {
                const allGroups = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    allGroups.push(data)
                })
                resolve(allGroups)
            })
        } catch (e) {
            reject(e)
        }

        return response

    })

    return response
})
export const getImageURL = createAsyncThunk('chat/createGroup', async (data) => {
    let newImage = data
    if (data.size > 1000000) newImage = await imageCompressor(data)
    const formData = new FormData()
    formData.append('file', newImage)
    const response = await axiosInstance.post('upload-multipartFile', formData)
    return response.data
})

export const cteateGroup = createAsyncThunk('chat/createGroup', async (data) => {
    let { linkType, user1, user2 } = data
    await setDoc(doc(collection(db, "group")), {
        chatType: linkType,
        members: [
            {
                [`${user1}`]: {
                    displayName: user1.displayName,
                    photoURL: user1.photoURL
                }
            }, {
                [`${user2}`]: {
                    displayName: user2.displayName,
                    photoURL: user2.photoURL
                }
            }
        ],
        date: serverTimestamp()
    }).catch(err => console.error(err))
})


const initialState = {
    currentUser: undefined,
    selectedUser: undefined,
    chat: [],
    groups: []
}

export const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
        setChat: (state, action) => {
            state.chat = action.payload
        },
        setGroup: (state, action) => {
            state.groups = action.payload
        }
    }

})
export const { setCurrentUser, setSelectedUser, setChat, setGroup } = supportSlice.actions
export default supportSlice.reducer
