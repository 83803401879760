import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics"
import { getMessaging, isSupported } from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyBwbV1LONfegh6aLhJEif8ukTqeI6UW1qA",
    authDomain: "shopinzip-f1d03.firebaseapp.com",
    databaseURL: "https://shopinzip-f1d03-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "shopinzip-f1d03",
    storageBucket: "shopinzip-f1d03.appspot.com",
    messagingSenderId: "697243508368",
    appId: "1:697243508368:web:8c10eeca8a309ec4050215",
    measurementId: "G-7CCE9T5GQ6"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth()
export const storage = getStorage()
export const db = getFirestore()
const analytics = getAnalytics(app)

export let messaging
try {
    isSupported().then((data) => {
        if (data) messaging = getMessaging(app)
    })
} catch {
    console.log("not connected to secure network")
}