import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/authentication/AxiosInterceptor'

export const getOrdersCounts = createAsyncThunk('orders/getOrdersCounts', async (id) => {
    const response = await axiosInstance.get(`dashboard/order/info?storeId=${id}`)
    return response.data
})

export const getRevenueInfo = createAsyncThunk('revenue/getRevenueInfo', async (id) => {
    const response = await axiosInstance.get(`dashboard/store-revenue/info?storeId=${id}`)
    return response.data
})

export const getRevenueLatest = createAsyncThunk('revenue/getRevenueLatest', async (id) => {
    const response = await axiosInstance.get(`dashboard/today-store-revenue/info?storeId=${id}`)
    return response.data
})
export const getStoreProductExcel = createAsyncThunk('excel/getStoreProductExcel', async (id) => {
    const response = await axiosInstance.get(`dashboard/store-product/category/info/report?storeId=${id}`)
    return response.data
})

export const getstoreFoodExcel = createAsyncThunk('excel/getstoreFoodExcel', async (id) => {
    const response = await axiosInstance.get(`dashboard/store-food/category/info/report?storeId=${id}`)
    return response.data
})
export const getFoodInfo = createAsyncThunk('food/getFoodInfo', async (id) => {
    const response = await axiosInstance.get(`dashboard/store-food/info?storeId=${id}`)
    return response.data
})

export const getAdminInfo = createAsyncThunk('admininfo/getAdminInfo', async () => {
    const response = await axiosInstance.get(`dashboard/admin/info`)
    return response.data
})

export const getStoreInfo = createAsyncThunk('getStoreInfo/getStoreInfo', async (data) => {
    const response = await axiosInstance.get(`dashboard/store/info?storeId=${data.storeId}`)
    return response.data
})

export const getRevenueGraph = createAsyncThunk('graph/getRevenueGraph', async (data) => {
    const response = await axiosInstance.get(`dashboard/store-payout/report?${Object.keys(data).map(key => `${key}=${data[key]}`).join('&')}`)
    return response.data
})

export const getFoodPieGraph = createAsyncThunk('food/getFoodPieGraph', async (id) => {
    const response = await axiosInstance.get(`dashboard/store-food/category/info?storeId=${id}`)
    return response.data
})

export const getOrderDetailPdf = createAsyncThunk('pdf/getOrderDetailPdf', async (orderId) => {
    const response = await axiosInstance.get(`/order/${orderId}/receipt`)
    return response.data
})

export const getOtpRefund = createAsyncThunk('otp/getOtpRefund', async () => {
    const response = await axiosInstance.post(`/vendor/transaction/send-otp`)
    return response.data
})

export const getOtpConfirmation = createAsyncThunk('otp/getOtpRefundConfirm', async (data) => {
    const response = await axiosInstance.post(`/vendor/transaction/confirm-payment?${Object.keys(data).map(key => `${key}=${data[key]}`).join('&')}`)
    return response.data
})

export const getOtpRefundAmount = createAsyncThunk('otp/getOtpRefundAmount', async (data) => {
    const response = await axiosInstance.post(`vendor/transaction/custom-amount/send-otp?${Object.keys(data).map(key => `${key}=${data[key]}`).join('&')}`)
    return response.data
})

export const getOtpRefundConfirm = createAsyncThunk('otp/getOtpRefundAmount', async (data) => {
    const response = await axiosInstance.post(`vendor/transaction/custom-amount/confirm-payment?${Object.keys(data).map(key => `${key}=${data[key]}`).join('&')}`)
    return response.data
})

// GET/dashboard/store-product/info
export const getProductCount = createAsyncThunk('product/getProductCount', async (id) => {
    const response = await axiosInstance.get(`dashboard/store-product/info?storeId=${id}`)
    return response.data
})

export const getCategoryInfo = createAsyncThunk('categoryInfo/getCategoryInfo', async (id) => {
    const response = await axiosInstance.get(`dashboard/store-product/category/info?storeId=${id}`)
    return response.data
})

export const getNotifications = createAsyncThunk('notification/getNotifications', async (data) => {
    const response = await axiosInstance.post(`notification/curbside/list?${Object.keys(data).map(key => `${key}=${data[key]}`).join('&')}`)
    return response.data
})


const initialState = {
    id: ""
}

export const storeIdSlice = createSlice({
    name: 'id',
    initialState,
    reducers: {
        setId: (state, action) => {
            state.id =  action.payload
          }

    }
    
})

export const {setId} = storeIdSlice.actions

export default storeIdSlice.reducer
