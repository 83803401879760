import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../shared/authentication/AxiosInterceptor'
import { imageCompressor } from '../../shared/components/imageCompressor/imageCompressor'
import { StoreData } from '../models/Store'

export const getStore = createAsyncThunk('stores/getStore', async (id) => {
    const response = await axiosInstance.get(`store/${id}`)
    return response.data
})
export const getPrimaryCategory = createAsyncThunk('stores/primaryCategory', async (id) => {
    const response = await axiosInstance.get(`store/storeType/list`)
    return response.data
})
export const getPages = createAsyncThunk('categories/getPage', async ({ page, generalSearch, customMasterId = 0, perPage = 10 }) => {

    let body = new FormData()
    body.append("perpage", perPage)
    body.append("page", page)
    body.append("generalSearch", generalSearch)
    body.append("customMasterId", customMasterId)
    const response = await axiosInstance.post(`store/page`, body)
    return response.data
})
export const getDeactivatePages = createAsyncThunk('categories/deactivePages', async ({ page, generalSearch, customMasterId = 0, perPage = 10 }) => {

    let body = new FormData()
    body.append("perpage", perPage)
    body.append("page", page)
    body.append("generalSearch", generalSearch)
    body.append("customMasterId", customMasterId)
    const response = await axiosInstance.post(`store/deactive/page`, body)
    return response.data
})
export const getStores = createAsyncThunk('stores/getStores', async (search) => {
    const formData = new FormData()
    formData.append('search', search)
    const response = await axiosInstance.post('store/active', formData)
    return response.data
})

export const getOrderStore = createAsyncThunk('stores/getOrderStore', async (id) => {
    const response = await axiosInstance.get(`order/store/${id}`)
    return response.data
})

export const getstoreListExcel = createAsyncThunk('stores/getstoreListExcel', async (id) => {
    const response = await axiosInstance.get(`/store/${id}/store-list/excel`)
    return response.data
})

export const getPrivacyPolicy = createAsyncThunk('stores/getPrivacyPolicy', async (storeId) => {
    const response = await axiosInstance.put(`/store/${storeId}/read`)
    return response.data
})

export const getStoresBySearch = createAsyncThunk('stores/getStoresBySearch', async (searchString) => {
    const response = await axiosInstance.get(`store/search/${searchString}`)
    return response.data
})

export const addStore = createAsyncThunk('stores/addStore', async (data) => {
    const response = await axiosInstance.post('store', data)
    return response.data
})

export const onOffStore = createAsyncThunk('store/onOffStore', async (id) => {
    const response = await axiosInstance.post(`store/${id}/exists`)
    return response.data
})

// /delbird-delivery/vehicle

export const getVehicleOptions = createAsyncThunk('store/onOffStore', async (id) => {
    const response = await axiosInstance.get(`/delbird-delivery/vehicle`)
    return response.data
})

export const deleteStore = createAsyncThunk('stores/delete', async (id) => {

    const response = await axiosInstance.delete(`store/${id}`)
    return response.data
})

export const getDeactivateStores = createAsyncThunk('stores/getDeactivateStores', async (search) => {
    const formData = new FormData()
    formData.append('search', search)
    const response = await axiosInstance.post('store/deactive', formData)
    return response.data
})

export const deactivateStore = createAsyncThunk('stores/deactivateStore', async (data) => {
    const { id, status } = data

    const formData = new FormData()
    formData.append('status', status)
    const response = await axiosInstance.put(`store/deactive/${id}`, formData)
    return response.data
})


export const uploadStoreImage = createAsyncThunk('stores/uploadStoreImage', async (data) => {
    let newImage = data
    if (data.size > 1000000) newImage = await imageCompressor(data)
    const formData = new FormData()
    formData.append('file', newImage)
    const response = await axiosInstance.post('/upload-file', formData)
    return response.data
})

export const getStoreImage = createAsyncThunk('stores/getStoreImage', async (filename) => {
    const response = await axiosInstance.get(`/get-store-image//${filename}`)
    return response.data
})


export const getStoreOrdersByStatus = createAsyncThunk('stores/getStoreOrdersByStatus', async (data) => {
    const { currentPage, perPage, searchValue, orderStatus, id } = data

    let body = new FormData()
    body.append("page", currentPage)
    body.append("perpage", perPage)
    body.append("generalSearch", searchValue)
    body.append("status", orderStatus)
    body.append("storeId", id)

    const response = await axiosInstance.post('order/store/page/status', body)
    return response.data
})

export const getStoreOrderStatusCount = createAsyncThunk('stores/getStoreOrderStatusCount', async (id) => {
    const response = await axiosInstance.get(`order/store/${id}`)
    return response.data
})

export const getCustomerReview = createAsyncThunk('stores/getCustomerReview', async (data) => {
    const response = await axiosInstance.get(`review/store/${data.storeId}?${Object.keys(data).map(key => `${key}=${data[key]}`).join('&')}`)
    return response.data
})

const initialState = {
    stores: [StoreData]
}

export const storeSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(getStores.fulfilled, (state, action) => {
                Object.assign(state.stores, action.payload)

            })

    }
})


export default storeSlice.reducer
