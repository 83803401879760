export const ProductDataModel = {
    sku: "",
    name: "",
    description: "",
    returnPolicy: "",
    comment: "",
    manufacturingName:"",
    manufacturingDetail:"",
    manufacturingContact:"",
    barcode: "",
    status: "",
    images: null,
    categories: null,
    active: true,
    productSizes: [
        {
            size: "",
            unit: "",
            quantity: ""
        }
    ],
    tags: "",
    label: "",
    price: 0,
    taxable: 0,
    taxPercentage: 0,
    discount: 0,
    crossPrice: 0,
    tax: undefined,
    included: true

}