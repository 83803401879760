// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getChatContacts } from '../chat/store'
import axiosInstance from '../shared/authentication/AxiosInterceptor'

export const getBookmarks = createAsyncThunk('layout/getBookmarks', async () => {
  const response = await axios.get('/api/bookmarks/data')
  return {
    data: response.data

  }
})
export const getNotification = createAsyncThunk('layout/getBookmarks', async ({ page, storeId }) => {
  const formData = new FormData()
  formData.append('page', Number(page))
  formData.append('storeId', Number(storeId))
  const response = await axiosInstance.post('/panel-notification/page', formData)
  return {
    data: response.data.data
  }
})
export const fulfilleds = createAsyncThunk('layout/fullfiled', async (data) => {
  return data

})
export const timeouts = createAsyncThunk('layout/timeouts', async (data, { dispatch }) => {
  return setTimeout(() => {
    dispatch(fulfilleds(data.orderId))
  }, data.time)

})

export const updateBookmarked = createAsyncThunk('layout/updateBookmarked', async id => {
  await axios.post('/api/bookmarks/update', { id })
  return id
})

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    query: '',
    bookmarks: [],
    suggestions: [],
    layout: [],
    popUpOrder: false
  },
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.payload
    },
    handleOrdePopup: (state, action) => {
      state.popUpOrder = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.suggestions = action.payload.data
        state.bookmarks = action.payload.bookmarks
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate

        // ** find & update object
        state.suggestions.find(item => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
            objectToUpdate = item
          }
        })

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.payload)

        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate)
        } else {
          state.bookmarks.splice(bookmarkIndex, 1)
        }
      })

      .addCase(fulfilleds.fulfilled, (state, action) => {
        state.popUpOrder = action.payload
      })

  }
})

export const { handleSearchQuery, handleOrdePopup } = layoutSlice.actions

export default layoutSlice.reducer
