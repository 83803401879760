import { createSlice, current } from "@reduxjs/toolkit"

const initialState = {
    deleteStore: false
}

export const deletedStoreSlice = createSlice({
    name: 'deleteStore',
    initialState,
    reducers: {
        setDeletedStore: (state, action) => {
            state.deleteStore = action.payload
        }
    }
})

export const { setDeletedStore } = deletedStoreSlice.actions


export default deletedStoreSlice.reducer
