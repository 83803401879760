export const StoreData = {
    gstNumberAvailable: false,
    gstNumber: "",
    fssaiNumber: "",
    address1: "",
    name: "",
    description: "",
    qrCode: "",
    storeType: "",
    contactNumber: "",
    email: "",
    openingTime: undefined,
    deliveryDayChange: undefined,
    closingTime: undefined,
    inventoryType: undefined,
    pickup: false,
    curbside: false,
    storeDeliveryDuration: "",

    openingTime1: undefined,
    openingTime3: undefined,
    openingTime2: undefined,
    openingTime4: undefined,
    openingTime5: undefined,
    openingTime6: undefined,
    openingTime0: undefined,
    closingTime1: undefined,
    closingTime2: undefined,
    closingTime3: undefined,
    closingTime4: undefined,
    closingTime5: undefined,
    closingTime6: undefined,
    closingTime0: undefined,
    review: "",
    status: "",
    storeOnline: false,
    image: "",
    banner: "",
    address: [],
    idProof: "",
    registrationProof: "",
    addressProof: "",
    startingArea: "",
    zipcode: "",
    endingArea: "",
    startingAreaZipcode: "",
    endingAreaZipcode: "",
    deliveryCharge: "free",
    // returnPolicy: [],
    termsConditions: "",
    storeTiming: [],
    returnPolicyDesc: "",
    returnPolicyType: "",
    pickupReadyIn: "",
    storeDeliveryDay: [],
    storePrimaryType: undefined,
    storeSecondaryType: undefined,
    deliveryFees: 0,
    minAmtDeliveryFees: 0,
    uniqueNumber: 0
}
export const weekDays = {
    sunday: 0, monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6

}
export const validationTimes =

{
    openingTime1: undefined,
    openingTime3: undefined,
    openingTime2: undefined,
    openingTime4: undefined,
    openingTime5: undefined,
    openingTime6: undefined,
    openingTime0: undefined,
    closingTime1: undefined,
    closingTime2: undefined,
    closingTime3: undefined,
    closingTime4: undefined,
    closingTime5: undefined,
    closingTime6: undefined,
    closingTime0: undefined,
    openingTime21: undefined,
    openingTime22: undefined,
    openingTime23: undefined,
    openingTime24: undefined,
    openingTime25: undefined,
    openingTime26: undefined,
    openingTime20: undefined,
    closingTime21: undefined,
    closingTime22: undefined,
    closingTime23: undefined,
    closingTime24: undefined,
    closingTime25: undefined,
    closingTime26: undefined,
    closingTime20: undefined
}

