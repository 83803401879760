// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import axiosInstance from '../shared/authentication/AxiosInterceptor'
import { getToken } from 'firebase/messaging'
import { messaging } from '../shared/firebase/firebase'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}
export const sendForgotPassMail = createAsyncThunk('password/forgetPassword', async (data) => {
  const response = await axiosInstance.get(`user/forgot/password?email=${data}`)
  return response.data
})

export const changePassword = createAsyncThunk('password/changePassword', async (data) => {
  const response = await axiosInstance.put(`user/change/password?oldPassword=${data?.oldPassword}&newPassword=${data?.newPassword}`)
  return response.data
})
export const sendBrowserToken = createAsyncThunk('notification/add-token', async (storeId) => {
  // async function getTokens(storeId) {
  return Notification.requestPermission().then((permission) => {
    if (permission === "granted" && messaging) {
    
      return getToken(messaging, { vapidKey: `BE7qUjBOo8MAg99Bngf9kXeQwZYT3pipxXDneUyYvujdCvwku-2dZxMAjgstQf59e2Grf5GhDtWmjH4I7DD--ik` })
        .then(async (currentToken) => {
          if (currentToken) {
            const formData = new FormData()
            formData.append('token', currentToken.toString())
            formData.append('storeId', storeId)
            localStorage.setItem('browserToken', currentToken)
            const response = await axiosInstance.post(`notification/add-token`, formData)
            return response.data
          } else {
          }
        })
        .catch((err) => {
        })
    } else {
    }
  })
  // }
})
export const clearBrowserToken = createAsyncThunk('notification/add-token', async () => {
  const token = localStorage.getItem('browserToken')

  if (token) {

    const formData = new FormData()
    formData.append('token', token)
    const response = await axiosInstance.post(`notification/delete`, formData)
    localStorage.removeItem('browserToken')
    return response.data
  }
})
export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    browserToken: ""
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))

      if (action.payload["stores"].length > 0) {
        const storeId = action.payload["stores"][0].id
        localStorage.setItem('storeId', storeId)

      }


      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('storeId')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  }
})


export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
