// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import stores from '../store/reducers/StoreReducer'
import customMaster from '../custom-master/reducers/CustomMasterReducer'
import customers from '../customer/reducers/CustomerReducer'
import products from '../product/reducers/ProductReducer'
import deletedStore from '../store/reducers/DeletedStoreReducer'
import support from '../chat/reducers/SupportReducer'
import dashboardId from '../dashboard/dashboardCards/Reducer/DashboardReducer'

const rootReducer = {
  auth,
  navbar,
  layout,
  stores,
  customMaster,
  customers,
  products,
  deletedStore,
  support,
  dashboardId

}

export default rootReducer
